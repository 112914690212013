.admin {
    flex-direction: column;
    align-items: center;

    margin: 1rem 0;
}

.admin > .p-card {
    width: 60%;
    height: fit-content;
    padding: 2rem 1rem;
}

.admin .row {
    width: 80%;
}

.admin-title {
    text-align: center;
}

/* --- TabView --- */
.p-tabview div,
.p-tabview .p-tabview-nav,
.p-tabview-nav li,
.p-tabview .p-tabview-nav li .p-tabview-nav-link  {
    background: none;
}

.p-tabview .p-tabview-nav .p-tabview-nav-link {
    color: var(--dark-gray);
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: var(--blue);
}

.admin table {
    width: 100%; /* Ensure the table has a defined width */
    table-layout: fixed; /* Fix the table layout */
}

.admin tr {
    width: 100%; /* Ensure rows take full width of the table */
}

.admin .p-datatable-table th,
.admin td {
    width: calc(100% / 6); /* Apply the same width calculation to both th and td */
    box-sizing: border-box; /* Include padding and border in the width calculation */
}

.admin .product-image-container > div {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0.2rem;
}

.admin .product-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.admin .description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* --- CREATE PRODUCT --- */

.upload-image span {
    background-color: var(--yellow);
    color: var(--dark-gray);
    font-family: var(--heading-font), sans-serif;
}

.admin form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    width: 100%;

    padding: 0 3rem;
}

.admin .p-inputtext {
    width: 100%;
}

.admin .row {
    width: 100%;
}

.admin .row > span {
    width: 50%;
}

.admin .row .p-multiselect,
.admin .row .p-inputnumber {
    width: 100%;
}

/* --- RESPONSIBILITY --- */
@media screen and (max-width: 1024px){
    .admin .p-card {
        width: 98%;
    }

    .admin .p-tabview-panels {
        width: fit-content;
        padding: 0;
    }

    .admin .p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
        min-width: 2rem;
        height: 2rem;
    }

    .admin .p-paginator .p-paginator-pages .p-paginator-page {
        min-width: 1.5rem;
        height: 1.5rem;
    }
}