@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    /* --- COLORS --- */
    --dark-gray: #2F2F2F; /* Headings, navbar and footer background */
    --light-gray: #52514F; /* Text color */

    --beige: #F9EDE0; /* Background color */
    --white: #FAF9F7; /* Secondary background color, text for dark backgrounds */

    --yellow: #EFDD8D; /* Buttons, highlights  */
    --blue: #007BFF; /* Links, hover buttons, highlights */
    --red: #D63230; /* Error and warning messages */
    --green: #84C318; /* Success messages */


    /* --- FONTS --- */
    --heading-font: 'poppins'; /* headings  */
    --text-font: 'Almarai'; /* text */
    --font-family: 'Almarai'; /* setting style for PrimaReact styles */
}


body {
    font-family: var(--text-font), sans-serif;
    font-size: 16px;

    color: var(--light-gray);

    background-color: var(--beige);

    margin: 0;
    padding: 0;
}

#root {
    min-height: 100vh;

    display: flex;
    flex-direction: column;
}

.unset {
    all: unset;
}

.content {
    padding-top: 10vh;
}

.p-card {
    background-color: var(--white);
}

/* --- FLEX POSITIONING --- */

.justify-center {
    display: flex;
    justify-content: center;
}

.align-center {
    display: flex;
    align-items: center;
}

.column {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;

    gap: 1rem;
}

.row .half {
    width: 50%;
    margin: 0;
}

/* --- MARGIN --- */

.m-left-auto {
    margin-left: auto;
}

.m-top-auto {
    margin-top: auto;
}

/* --- BUTTON --- */

.button {
    background-color: var( --yellow );

    font-family: var(--heading-font), sans-serif;
    color: var(--dark-gray);
    text-decoration: none;
    white-space: nowrap;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

.button:active {
    background-color: #EFDD8D95;
}

.button-text {
    color: var(--dark-gray);
    background: none;
}

.button-text:active {
    background-color: #EFDD8D30;
}

/* --- TEXT --- */

h1, h2, h3, h4, h5, h6 {
    font-family: var(--heading-font), sans-serif;
    color: var(--dark-gray);
}

h2, h3, h4, h5, h6 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
    font-weight: 700;
}

h2 {
    font-size: 1.6rem;
}

h3 {
    font-size: 1.4rem;
}

a {
    color: var(--blue);
}

/* --- INPUTS --- */
.p-inputtext:enabled:hover {
    border-color: var(--dark-gray);
}

.p-inputtext:enabled:focus {
    border-color: var(--blue);
}

/* --- RESPONSIVE --- */
@media (max-width: 768px) {
    .row {
        flex-direction: column;
    }

    .row .half {
        width: 100%;
    }
}