/* --- CART --- */
.cart-size {
    width: 60%;
    height: 85vh;
    margin: 1rem 0;
}

.auto-height {
    height: auto;
}

.cart {
    height: auto;

    margin: 0;
    padding: 1rem;
}

.cart-container .user-form {
    width: 100%;
    padding: 0;
}

/* --- container --- */
.cart-container {
    display: flex;
    flex-direction: column;
}

.cart-container .total-price {
    margin-top: 0.8rem;
    align-items: start;
}

.cart-container .total-price h3,
.cart-container .total-price span {
    margin: 0;
    font-size: 1.2rem;
}

/* --- part cart --- */
.middle-cart {
    display: flex;
    flex-direction: column;

    height: 80%;

    margin: 1rem 0 0;
}

.middle-cart h2 {
    margin: 0.5rem;
    font-size: 1.5rem;
}

.row-cart-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.row-cart {
    margin: 0;

    width: 50%;
    height: 100%;
}

.end-cart {
    margin-top: 1rem;
}

.cart-content {
    padding: 0 3rem 0.5rem;
}

.cart-title {
    margin-bottom: 0;
    text-align: center;
}

.cart-title h1 {
    margin: 0.2rem 0;
}

/* --- overflow --- */
.dataview-scroll {
    overflow-y: scroll;
}

/* --- FOOTER --- */
.total-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.total-price span {
    font-size: 1.2rem;
}

.end-cart .total-price {
    margin-bottom: 1rem;
}

.cart-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.cart-footer.one-button {
    margin-left: auto;
}

/* --- TABLE --- */
.p-dataview-content {
    all: unset;
}

.p-dataview-emptymessage {
    margin: 0 2rem;
}

.cart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
}

.cart-item:nth-child(even) {
    background-color: #52514F08;
}

.cart-item img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 4px;
}

.cart-item > div {
    display: flex;
    flex-direction: row;
    width: 100%;

    align-items: center;
}

.cart-item-title {
    margin: 0 auto 0 1rem;
}

.cart-item-info {
    display: flex;
    flex-direction: row;

    width: 50%;
}

.item-count {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.item-count .icon-button {
    height: 1.2rem;
}

.item-count .num-input {
    height: 1.5rem;
    width: 3rem;

    font-size: 1.2rem;
    text-align: center;
}

.item-price {
    display: flex;
    flex-direction: row;
    gap: 2rem;

    width: fit-content;
}

/* --- summary version --- */
.summary.cart-item {
    padding: 1rem 0.5rem;
}

.summary .cart-item-title {
    width: 30%;
}

.summary .cart-item-info {
    flex-direction: column;
    width: 70%;
}

.summary .cart-item-info > * {
    width: 100%;
    justify-content: start;
    text-align: start;
    margin: 0;
}

.summary .cart-item-price {
    margin-left: auto;
}

/* --- form --- */
.cart .user-form {
    width: 100%;
    gap: 1rem;
}
/* --- --- --- */
.radio-container {
    display: flex;
    flex-direction: row;
}

.radio-group {
    width: 50%;
}

.radio-group > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.radio,
.radio label {
    display: flex;
    white-space: nowrap;
    align-items:center;
}

.radio {
    gap: 1.5rem;
}

.radio label {
    gap: 0.3rem;
}

.radio svg {
    font-size: 1.2rem;
}

/* --- radio color --- */
.p-radiobutton.p-highlight .p-radiobutton-box {
    border-color: var(--blue);
}

.p-radiobutton-icon {
    background-color: var(--blue);
}

/* --- SUMMARY --- */
.summary-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.summary-content span {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.contact-summary {
    gap: 5rem;
}

.summary-content .name {
    font-size: 1.4rem;
}

.summary-address {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.summary-address > div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.price-chip {
    display: flex;
    flex-direction: column;

    align-items: center;
    gap: 0.5rem;
}

.p-chip {
    background-color: var(--yellow);
    padding: 0.5rem 1rem;

    border-radius: 20px;

    display: flex;
    white-space: nowrap;
    gap: 0.5rem;
    align-items: center;

    font-size: 0.8rem !important;
}

@media screen and (max-width: 1248px) {
    .cart-size {
        width: 90%;
    }
}


/* --- RESPONSIBILITY --- */
@media screen and (max-width: 1024px){
/* --- cart --- */
    .cart-size {
        height: fit-content;
    }

    .cart-title h1 {
        font-size: 1.8rem;
    }

    .cart-content {
        padding: 2rem 1rem;
    }

/* --- items --- */
    .cart-item {
        padding: 0.5rem 0;
    }

    .cart-item > div {
        flex-direction: column;
        gap: 0.5rem;
    }

    .cart-item img {
        height: 80px;
        width: 80px;
    }

    .cart-item-title {
        margin: 0;
        text-align: center;
    }

    .cart-item-info {
        gap: 1rem;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        width: 100%;
    }

    .item-count {
        margin-left: 0;
    }

/* --- delivery --- */
    .radio-container {
        flex-direction: column-reverse;
    }

    .radio-group {
        width: 100%;
    }

/* --- summary --- */
    .row-cart-container {
        flex-direction: column;
    }

    .row-cart {
        width: 100%;
    }

    .row-cart h2 {
        margin-left: 0;
    }

    .contact-summary {
        gap: 1rem;
        padding: 1.5rem 1rem !important;
    }
}