.form-card {
    width: 50%;
    height: auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 5vh 0;

}


.user-form {
    width: 60%;

    display: flex;
    flex-direction: column;
    padding: 1rem 0 2rem;
    gap: 2rem;
}

.user-form input[type="submit"] {
    width: 100%;
    margin-top: 1rem;
}

.text-input {
    width: 100%;
}

.p-password-input,
.p-inputtext {
    background-color: var(--white);
}

.p-float-label label {
    background-color: var(--white);
}

.password-input {
    width: 100%;
}

.password-input > * {
    width: 100%;
}

.p-password-input {
    width: 100%;
}

.p-password > * {
    width: 100%;
}

.inputs-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.name-container {
    display: flex;
    gap: 1rem;
}

.name-container .p-float-label {
    width: 50%;
}

.password-container {
    display: flex;
    flex-direction: column;
}

.password-container a {
    margin-top: 0.3rem;
}

.w-100 {
    width: 100%;
    background: var(--white);
}

@media screen and (max-width: 768px) {
    .form-card {
        width: 95%;
    }

    .user-form {
        width: 90%;
    }

}
