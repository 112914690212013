.item-details {
    width: 80%;
    margin: 1rem auto;
}


.item-info {
    margin: 0;
    padding: 1rem 3rem;

    width: 70%;

    display: flex;
    flex-direction: column;
}

.info-img {
    width: 30%;
    height: auto;

}

.info-img img {
    height: 100%;
    width: 100%;

    object-fit: cover;
}

.item-details .title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    text-align: center;
    margin: 0 0 1rem;
    position: relative;
}

.item-details .title h1 {
    flex-grow: 1;
    text-align: center;

    margin-bottom: 0;
}

.item-details .title a {
    position: absolute;
    left: 0;
    top: 0;

    margin: 1rem;
}

.allergens {
    margin-bottom: 1.5rem;
}


.info-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-top: auto;
}

.info-footer h3 {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.weight {
    margin-top: auto;
    font-size: 1.2rem;

    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.event .info-footer {
    padding: 0 2rem;
}

.product .info-footer {
    height: 5vh;
    margin-top: 1rem;
}

.info-footer .contacts {
    display: flex;
    flex-direction: column;

    margin: 0;
}

.info-footer .contacts a {
    text-decoration: none;
    font-size: 1.2rem;

    display: flex;
    align-items: center;

    margin-bottom: 0.8rem;
}

.info-footer .contacts svg {
    margin-right: 0.5rem;
    height: 1.5rem;
    width: auto;
}

span.p-chip {
    width: fit-content;
}

@media screen and (max-width: 1200px){
    .item-details .title a {
        margin: 0.5rem;
    }

    .item-details {
        width: 95%;
    }

    .item-info {
        width: 100%;
        height: auto;

        padding: 1rem;
    }

    .info-img {
        width: 100%;
    }

    .event .info-footer {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 0;
    }

    .contacts {
        gap: 0.5rem;
    }

    .product .info-footer {
        min-height: 10vh;
        height: fit-content;
    }
}

.item-details.event .carousel-container {
    width: 100%;
    height: 60vh;
    margin-bottom: 2rem;
}

.item-details.event .p-carousel {
    height: 100%;
}

.item-details.event .carousel-item-container {
    height: 60vh;
}

.item-details.event .item-info {
    width: 100%;
}

.item-details.event .info-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
