.order {
    margin: 1rem;
}

.order .row {
    display: flex;
    justify-content: space-between;
}

.order .row > div {
    width:50%;
}

.order .p-card {
    width: 80%;
    min-height: 85vh;
    height: fit-content;

    padding: 1rem 3rem;
}

.order-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.order h3 {
    margin: 0;
}

.order p {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    width: fit-content;
}

.order svg {
    width:auto;
    height: 1.2rem;
}