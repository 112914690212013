/* --- ABOUT US --- */
.about {
    background-color: var(--white);
    display: flex;

    flex-direction: row;
    justify-content: space-between;

    width: 100%;
    height: 70vh; /* TODO: solve the responsibility problem */
}

.about h1 {
    text-align: center;
}

.text {
    width: 40%;
    padding: 1rem 2rem;
}

.image {
    height: 100%;
    width: 55%;
    overflow: hidden;

    display: flex;
    align-items: center;
}

.image img {
    width: auto;
    height: 200%;

    object-fit: cover;


    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
}

@media screen and (max-width: 768px) {
    .about {
        flex-direction: column;
        height: 100%;
    }

    .text {
        width: 100%;
    }

    .image {
        display: none;
    }
}

/* --- CARDS --- */
.home-cards {
    width: 90% !important;
    justify-content: space-evenly !important;
    align-items: stretch;

    margin: 2rem 0 4rem;
}

.home-cards .card {
    width: 25%;
}

.home-cards .p-card {
    width: 100%;
    height: auto;
}

.home-cards .p-card-header  {
    height: 400px;
}

.home-cards .p-card-header img {
   mask-image: none;
}

.home-cards .p-card-title {
    margin: 0.5rem 0;
    font-size: 2.2rem;
    text-align: center;
    color: var(--dark-gray);
}

.home-cards .p-card-body {
    all: unset;
    padding: 1rem;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 768px) {
    .home-cards {
        flex-direction: column;
        align-items: center;
    }

    .home-cards .card {
        width: 100%;
    }
}



/* -- GALLERY -- */
.galleryContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5vh 0;
}

.gallery {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 16px;
    justify-content: center;
    align-items: center;
}

.galleryImg {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.galleryImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.p-galleria-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}
