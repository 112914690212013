/* --- LAYOUT --- */
.products {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.custom-header {
  height: 100%;
}

.product-link {
  text-decoration: none;
}

/* --- BUTTONS --- */

.icon-button {
  height: 1.8rem;
  width: auto;
}

.icon-button svg {
  height: 100%;
  width: auto;
}

/* --- INPUTS --- */

.quantity-display {
  width: 4rem;

  text-align: center;
    font-size: 1.5rem;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* --- FILTER --- */
.filter {
  width: calc(70% - 1rem);
  height:auto;

  margin-top: 2rem;
  padding: 1rem;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.p-multiselect {
  width: 30%;
}

.p-multiselect,
.p-multiselect-header,
.p-multiselect-item,
.p-multiselect-empty-message {

  background-color: var(--white);
  font-family: var(--text-font), sans-serif;
}

/*checkbox*/
.p-highlight .p-checkbox-box {
  background: var(--blue);
  border-color: var(--blue);
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: var(--blue);
}

/*chip*/
.p-multiselect-token {
  background-color: var(--yellow);
}

/*slider*/
.slider {
  background: var(--blue);
  width: 50%;
  height: 10px;
  border-radius: 20px;
}

/* --- RESPONSIBILITY --- */
@media screen and (max-width: 1200px){
  .filter {
    height: auto !important;
    width: 98%;
  }

  .p-multiselect {
    width: 100%;
  }

  .products .p-card {
    height: 50vh;
  }

  .p-card-header {
    height: 40%;
  }

  .p-card-body {
    height: 60%;
  }
}