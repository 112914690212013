/* --- PROFILE SITE --- */
.profile {
    display: flex;

    margin: 1rem 2rem;
    gap: 2rem;
}

/* --- ORDER HISTORY --- */

.history {
    width: 65%;
    height: fit-content;

    padding: 2rem 1rem;
}

.history h1 {
    margin: 0;
}

/* --- PROFILE INFO --- */

.info {
    width: 35%;
    height: fit-content;

    padding: 2rem 1rem;
}

.info .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}

.info .footer button {
    font-size: 1.4rem;

    display: flex;
    align-items: center;
    gap: 0.3rem;

    cursor: pointer;
}

/* --- CONTACTS --- */
.contacts {
    margin: 3rem 0;
}

.contacts p {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    white-space: nowrap;
}

.contacts p svg {
    font-size: 1.6rem;
}

.contacts h2 {
    margin: 0;
}

/* --- USER TYPE --- */
.user-type {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-type div {
    width: fit-content;
}

.user-type h1 {
    margin: 1rem 0 0;
}

.user-type p {
    margin: 0;
}

/* --- SETTINGS --- */
.settings {
    display: none;
    flex-direction: column;
    gap: 1rem;

    width: 80%;

    padding: 1rem;
    margin: 0 0.5rem;
    border-left: 1px solid var(--light-gray);
}

.settings form {
    display: none;
}

.settings button {
    cursor: pointer;
}

.settings button:hover {
    color: var(--blue);
}

.settings .button {
    background: var(--blue);
    border-radius: 0 4px 4px 0;

    color: var(--white);
}

.settings .p-button {
    min-width: 0;
}

.settings .button:hover {
    color: var(--white);
}

.settings .button:active {
    background: #007BFF95;
}

.settings .p-inputtext {
    padding: 0.5rem 1rem;
}

.settings .p-inputtext:focus {
    border-color: var(--blue);

}

.passwords {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.passwords .p-inputgroup,
.passwords .p-inputgroup input {
    height: 100%;
}

.p-inputgroup input {
    border-radius: 4px 0 0 4px;
}

/* --- CONFIRM DIALOG --- */
.p-dialog .p-button {
    background: var(--blue);
    color: var(--white);
}

.p-dialog .p-button:active {
    background: #007BFF95;
}

.p-dialog .p-button.p-button-text {
    background: transparent;
    color: var(--blue);
}

.p-dialog .p-button.p-button-text:active {
    background: #007BFF10;
}

/* --- TABLE --- */
.p-datatable {
    margin-top: 2rem;
}

.p-datatable * {
    background: transparent;
}

.p-datatable tr {
    height: 3rem;
}

.p-datatable th {
    font-family: var(--heading-font), sans-serif;
    padding: 0;
}

.p-datatable .p-column-header-content {
    align-items: center;
    justify-content: center;
}

.p-datatable td {
    padding: 0;
    text-align: center;

}

.p-row-odd {
    background: #52514F08;
}

.p-paginator {
    border: none;
}

.p-paginator .p-highlight {
    color: var(--blue);
}

.p-dropdown .p-focus {
    border-color: var(--blue);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: #007BFF10;
    color: var(--blue);
}

/* --- RESPONSIVE --- */
@media (max-width: 1024px) {
    .profile {
        flex-direction: column-reverse;
        margin: 1rem;
        gap: 0;
    }

    .info,
    .history {
        width: 100%;
        padding: 1rem;
    }
}

@media (max-width: 768px) {
    .settings {
        width: 100%;
    }
}
