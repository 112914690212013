/* --- NAVBAR --- */
.navbar {
    background-color: var(--dark-gray);
    color: var(--white);

    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: center;

    height: 10vh;
    width: 100%;
    padding: 0 2vw;

    position: fixed;
    top: 0;
    z-index: 100;
}

@media screen and (max-width: 768px) {
    .navbar {
        padding: 0.3rem 1rem;
    }
}

.navbar-content {
    display: flex;
    flex-direction: row;

    align-items: center;

    width: 20%;
}

.links {

    gap: 20px;
}

.icons {
    justify-content: end;
    gap: 2vw;
    height: 50%;
}

.icon {
    height: 2rem;
    width: auto;

    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar a {
    font-size: 1.2rem;
    height: 80%;

    display: flex;
    align-items: center;

    text-decoration: none;
    color: var(--white);
}

.navbar img {
    height: 80%;
}

.drop-down-button {
    height: 60%;
    width: auto;
}

.drop-down-button > * {
    height: 100%;
    width: auto;
}

.drop-down {
    display: none;
    flex-direction: column;
    align-items: center;

    position: absolute;
    top: 10vh;
    left: 0;
    width: 100%;
    z-index: 1001;

    background: #2F2F2FE5;
}

.drop-down a {
    font-size: 1.4rem;
    padding: 1rem;
}

/* --- FOOTER --- */
footer {
    background-color: var(--dark-gray);

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 2em;

    min-height: 10vh;
    width: 100%;
    padding: 1rem 2vw;

    margin-top: auto;
}

footer .contacts {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0;
}

footer * {
    color: var(--white) !important;
    text-decoration: none;
}

/* --- CARD ---*/
.card-container {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
}

.card-container > * {
    width: calc(100% / 3);
}

.p-card {
    height: 60vh;
    margin: 0.5rem;

    display: flex;
    flex-direction: column;
    background-color: var(--white);
    color: var(--light-gray);
}

.p-card-header {
    height: 60%;
}

.p-card-header img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px 4px 0 0;

    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0.5) 60%, rgba(0, 0, 0, 0.2) 85%, rgba(0, 0, 0, 0) 100%);
}

.p-card-body {
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    font-size: 1.4rem;
    text-align: center;
}

.p-card-title {
    font-size: 1.8rem;
    color: var(--dark-gray);
    text-align: center;

    margin-top: -3rem;
}

.p-card-subtitle {
    margin-top: auto;
}

.p-card-footer {
    width: 100%;
    height: 4rem;

    display: flex;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .card-container {
        width: 98%;
    }

    .card-container > * {
        width: 98%;
    }
}

/* --- HERO --- */
.hero {
    position: relative;
    min-height: 40vh;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(47, 47, 47, 0.8);
    z-index: 0;
}

.hero div {
    color: var(--white);
    z-index: 1;

    width: 50%;
}

.hero h1 {
    color: var(--white);
    font-size: 3.5rem;

    margin-bottom: 0;
}

.hero p {
    font-size: 1.4rem;
    margin-left: 10%;
}

@media screen and (max-width: 768px) {
    .hero div {
        width: 90%;
    }
}


/* --- 404 --- */
.not-found {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 90vh;
}

.not-found h1 {
    font-size: 10rem;
    line-height: 10rem;
    text-align: center;

    margin: 0;
}

.not-found h2 {
    font-size: 4rem;
    margin: 0.5rem 0 2rem;
}

.not-found p {
    font-size: 2rem;
    text-align: center;
}
